<template>
  <div class="students_table_wrapper">
    <table
      class="app_table table table-striped table-hover table-sm"
    >
      <thead class="table-light">
        <tr>
          <th scope="col">
            Name
          </th>
          <th scope="col">
            Surname
          </th>
          <th scope="col">
            E-mail
          </th>
          <th scope="col">
            Class Language
          </th>
          <th scope="col">
            Level
          </th>
          <th scope="col">
            Location
          </th>
          <th class="th_checkbox" scope="col">
            Pause Until
          </th>
          <th class="th_checkbox" scope="col">
            Flexi Classes
          </th>
          <th class="th_checkbox" scope="col">
            OLH Done
          </th>
          <th class="th_checkbox" scope="col">
            Is Active
          </th>
          <th class="th_checkbox remove_item" scope="col" />
        </tr>
      </thead>
      <tbody>
        <tr v-for="student in students" :key="student.id">
          <td class="td_name">
            {{ student.first_name }}
          </td>
          <td class="td_surname">
            {{ student.last_name }}
          </td>
          <td>{{ student.email }}</td>
          <td>{{ getMapping("class_language", student.class_language, "-") }}</td>
          <td>{{ getMapping("level", student.level, "-") }}</td>
          <td>{{ getMapping("class_location", student.class_location, "-") }}</td>
          <td
            v-tooltip="{content: studentPauseUntilText(student), disabled: !student.pause_until}"
            class="td_pause_until"
          >
            {{ formatDate(student.pause_until) }}
          </td>
          <td class="td_checkbox">
            <BooleanCheckCircle :is-true="student.flexible_classes_count > 0" />
          </td>
          <td class="td_checkbox">
            <BooleanCheckCircle :is-true="student.initial_course_passed" />
          </td>
          <td class="td_checkbox">
            <BooleanCheckCircle :is-true="student.is_active" />
          </td>
          <td class="td_checkbox remove_item hover-pointer" @click="removeClassStudent(student.id)">
            <i class="bi bi-trash3-fill text-danger" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import BooleanCheckCircle from "@/components/ui/BooleanCheckCircle.vue";
import { useStudentStore } from "@/stores/student.store";
import IStudent from "@/types/student";
import Student from "@/types/models/student";
import useDateMixin from "@/mixins/date.mixin";

export default defineComponent({
  name: "SchoolClassStudentsTable",
  components: { BooleanCheckCircle },
  mixins: [mappingsMixin],
  props: {
    studentIds: {
      type: Array as () => number[],
      default: () => [],
    },
  },
  emits: ["selected-students-changed"],
  setup() {
    const { formatDate } = useDateMixin();
    const studentStore = useStudentStore();
    const { attendanceStudents } = toRefs(studentStore);

    const students = ref<Student[]>([])
    return { studentStore, formatDate, attendanceStudents, students };
  },
  computed: {
    studentsMapping() {
      return this.attendanceStudents.reduce((acc: Record<number, any>, obj: IStudent) => {
        const idValue = obj.id as number;
        acc[idValue] = obj;
        return acc;
      }, {});
    },
  },
  watch: {
    studentsMapping: {
      handler() {
        console.log("attendance students changed");
        if (!this.attendanceStudents.length) {
          this.students = [];
          return
        }

        this.students = this.studentIds.map((studentId) => new Student(this.studentsMapping[studentId]));
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    removeClassStudent(studentId?: number) {
      if (studentId == null) return;
      const studentsWithoutRemovedStudentId = this.studentIds.filter(id => id != studentId);
      this.$emit("selected-students-changed", studentsWithoutRemovedStudentId);
    },
    studentFullName(student: Student) {
      let name = "";
      if (student.first_name) {
        name += `${student.first_name} `
      }
      if (student.last_name) {
        name += student.last_name
      }

      return name;
    },
    studentPauseUntilText(student: Student) {
      return `${this.studentFullName(student)} is on pause until ${ this.formatDate(student.pause_until, true) }`;
    },
  },
});
</script>

<style scoped lang="scss">
.students_table_wrapper {
  overflow: auto;
  margin-top: 1rem;
  height: 100%;

  @include sm {
    margin-top: 0;
  }
  table {
    border-collapse: collapse;
    height: 100%;
    thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
}
.td_surname,
.th_surname {
  width: 12rem;
  min-width: 12rem;
}
.td_name,
.th_name {
  width: 6rem;
  min-width: 6rem;
}
tr:hover {
  cursor: pointer;
}
.th_checkbox,
.td_checkbox {
  width: 4rem;
  min-width: 3rem;

  &.is_active {
    width: 50px;

    .form-check-input {
      width: 1.2em;
      height: 1.2em;
    }
  }
  &.remove_item {
    width: 50px;
    font-size: 1.1rem;
  }
}
</style>
