<template>
  <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-sm-down">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <template v-if="isEdit">
            Edit
          </template>
          <template v-else>
            Add
          </template>
          Class
        </h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="close"
        />
      </div>

      <div class="modal-body">
        <NavTabs
          v-if="isEdit"
          v-model="activeTab"
          :tabs="tabs"
          class="mb-3"
        />
        <SchoolClassInfo
          v-if="activeTab == 'classInfo'"
          v-model="schoolClass"
          :input-errors="inputErrors"
        />
        <SchoolClassEvents
          v-if="activeTab == 'events'"
          :school-class="schoolClass"
        />
      </div>

      <div class="modal-footer">
        <div v-if="inputErrors['non_field_errors']" class="w-100">
          <div class="alert alert-danger p-1 mt-3 mb-0" role="alert">
            {{ inputErrors["non_field_errors"] }}
          </div>
        </div>
        <div v-if="showOnlyOneStudentWarning" class="w-100">
          <div class="alert alert-warning p-1 mb-0" role="alert">
            This class is a group class but it only has one student. <br>
            Check if it is supposed to be an individual class.
          </div>
        </div>
        <div v-if="showManyStudentWarning" class="w-100">
          <div class="alert alert-warning p-1 mb-0" role="alert">
            This class is an individual class but it has more than one student. <br>
            Check if it is supposed to be a group class (uncheck Is Individual).
          </div>
        </div>
        <div v-if="isEdit && userStore.isCurrentTeacherAdmin" class="me-auto">
          <button type="button" class="btn btn-danger" @click="confirmDeleteClass">
            Delete
          </button>
        </div>
        <button type="button" class="btn btn-secondary" @click="close">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="loading"
          @click="save"
        >
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import { IInputErrors } from "@/types/input";
import { useUserStore } from "@/stores/user.store";
import { useCalendarStore } from "@/stores/calendar.store";
import timeMixin from "@/mixins/time.mixin";
import { useToastStore } from "@/stores/toast.store";
import { useModalStore } from "@/stores/modal.store";
import { ref } from "vue";
import SchoolClass from "@/types/models/school_class";
import { useStudentStore } from "@/stores/student.store";
import setsMixin from "@/mixins/sets.mixin";
import NavTabs from "@/components/ui/nav_tabs/NavTabs.vue";
import SchoolClassInfo from "@/components/modals/calendar/school_class/tabs/SchoolClassInfo.vue";
import SchoolClassEvents from "@/components/modals/calendar/school_class/tabs/SchoolClassEvents.vue";

export default defineComponent({
  name: "SchoolClassModal",
  components: {
    SchoolClassInfo,
    SchoolClassEvents,
    NavTabs,
  },
  mixins: [mappingsMixin, timeMixin, setsMixin],
  props: {
    schoolClassProp: {
      type: Object as PropType<SchoolClass>,
      default: () => {
        return null;
      },
    },
  },
  emits: ["close"],
  setup() {
    const userStore = useUserStore();
    const studentStore = useStudentStore();
    const calendarStore = useCalendarStore();
    const toastStore = useToastStore();
    const modalStore = useModalStore();
    const inputErrors = ref({} as IInputErrors);
    const schoolClass = ref();
    const loading = ref(false);
    const activeTab = ref("classInfo");

    return {
      userStore,
      studentStore,
      calendarStore,
      toastStore,
      modalStore,
      schoolClass,
      inputErrors,
      loading,
      activeTab,
    };
  },
  computed: {
    isEdit() {
      return this.schoolClass?.id != null;
    },
    schoolClassTeacher() {
      return this.schoolClass ? this.getMapping("teacher", this.schoolClass?.teacher) : undefined;
    },
    showOnlyOneStudentWarning(): boolean {
      // Show warning if only 1 student and class is not individual class.
      return !this.schoolClass?.is_individual && this.schoolClass?.students?.length === 1;
    },
    showManyStudentWarning(): boolean {
      return !!this.schoolClass?.is_individual && (this.schoolClass?.students?.length || 0) > 1;
    },
    tabs() {
      return [
        {
          key: "classInfo",
          title: "Class Info",
        },
        {
          key: "events",
          title: "Events",
        },
      ];
    },
  },
  mounted() {
    this.schoolClass = new SchoolClass({
      id: this.schoolClassProp?.id,
      teacher: this.schoolClassProp?.teacher,
      title: this.schoolClassProp?.title,
      class_location: this.schoolClassProp?.class_location ?? this.getDefaultChoice("class_location"),
      class_type: this.schoolClassProp?.class_type ?? this.getDefaultChoice("class_type"),
      class_language: this.schoolClassProp?.class_language ?? this.getDefaultChoice("class_language"),
      level: this.schoolClassProp?.level ?? this.getDefaultChoice("level"),
      weekday: this.schoolClassProp?.weekday,
      time: this.schoolClassProp?.time ?? "12:00",
      created: this.schoolClassProp?.created,
      is_active: this.schoolClassProp?.is_active,
      is_individual: this.schoolClassProp?.is_individual,
      google_meets_url: this.schoolClassProp?.google_meets_url,
      company: this.schoolClassProp?.company,
      students: this.schoolClassProp?.students,
      student_names: this.schoolClassProp?.student_names,
    });
  },
  created() {
    this.studentStore.resetState();
    this.studentStore.resetAttendanceStudents();
    if (this.schoolClassProp?.students) {
      this.studentStore.getAttendanceStudents(this.schoolClassProp?.students);
    }
  },
  methods: {
    confirmDeleteClass() {
      this.modalStore.openDeleteSchoolClassModal(this.schoolClass, this.deleteSchoolClass);
    },
    async save() {
      this.loading = true;
      try {
        await this.calendarStore.saveSchoolClass(this.schoolClass);
      } catch (error: any) {
        this.inputErrors = error;
        this.loading = false;
        return;
      }
      this.loading = false;

      this.close();
    },
    async deleteSchoolClass() {
      this.loading = true;
      try {
        await this.calendarStore.deleteSchoolClass(this.schoolClass);
      } catch (error: any) {
        this.inputErrors = error;
        this.loading = false;
        return;
      }
      this.loading = false;

      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
});
</script>
<style scoped lang="scss">
.modal-body {
  overflow: auto;
}
@include sm {
  .modal-content {
    max-height: 90vh;
  }
}
@include lg {
  .modal-dialog.modal-xl {
    --bs-modal-width: 1140px;
  }
}
.class_event_time_range {
  gap: 12px;
  display: flex;
}
.attendance_table_wrapper {
  overflow: auto;
}
:deep(.start_date) {
  max-width: 100%;
}
</style>
