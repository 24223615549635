<template>
  <div class="container main-container g-0 school_class_tab">
    <div class="row">
      <form v-if="schoolClass">
        <div class="container main-container g-0">
          <div class="row">
            <div class="col">
              <label for="class" class="col-form-label"> Teacher </label>
              <SelectInput
                id="class"
                v-model="schoolClass.teacher"
                :options="getChoices('teacher')"
                :class="{ 'is-invalid': inputErrors['teacher'] }"
                placeholder="Select a teacher..."
              />
              <div class="invalid-feedback">
                {{ inputErrors["teacher"] }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <label class="col-form-label"> Weekday </label>
              <SelectInput
                id="class"
                v-model="schoolClass.weekday"
                :options="getChoices('weekday')"
                :disable-search="true"
                :class="{ 'is-invalid': inputErrors['weekday'] }"
                placeholder="Select a weekday..."
              />
            </div>
            <div class="col">
              <div class="d-flex class_event_time_range">
                <div>
                  <label for="first_name" class="col-form-label"> Time </label>
                  <Timepicker v-model="schoolClass.time" :min="8" :max="21" />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-sm-4">
              <label for="class" class="col-form-label"> Class Language </label>
              <SelectInput
                id="class"
                v-model="schoolClass.class_language"
                :options="getChoices('class_language')"
                :disable-search="true"
                :class="{ 'is-invalid': inputErrors['class_language'] }"
                placeholder="Select a class language..."
              />
              <div class="invalid-feedback">
                {{ inputErrors["class_language"] }}
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <label for="level" class="col-form-label"> Language Level </label>
              <SelectInput
                id="level"
                v-model="schoolClass.level"
                :class="{ 'is-invalid': inputErrors['level'] }"
                :options="getChoices('level')"
              />
              <div class="invalid-feedback">
                {{ inputErrors["level"] }}
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <label for="level" class="col-form-label"> Company </label>
              <SelectInput
                id="company"
                v-model="schoolClass.company"
                :options="getChoices('company')"
                :disable-search="true"
                :class="{ 'is-invalid': inputErrors['company'] }"
              />
              <div class="invalid-feedback">
                {{ inputErrors["company"] }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <label for="class" class="col-form-label"> Class Location </label>
              <SelectInput
                id="class"
                v-model="schoolClass.class_location"
                :options="getChoices('class_location')"
                :disable-search="true"
                :class="{ 'is-invalid': inputErrors['class_location'] }"
                placeholder="Select a class location..."
              />
              <div class="invalid-feedback">
                {{ inputErrors["class_location"] }}
              </div>
            </div>
            <div class="col">
              <label for="class" class="col-form-label"> Class Type </label>
              <SelectInput
                id="class"
                v-model="schoolClass.class_type"
                :options="getChoices('class_type')"
                :disable-search="true"
                :class="{ 'is-invalid': inputErrors['class_type'] }"
                placeholder="Select a class type..."
              />
              <div class="invalid-feedback">
                {{ inputErrors["class_type"] }}
              </div>
            </div>
            <div class="col checkbox-center">
              <label class="col-form-label" for="is_class_active"> Is Class Active </label>
              <div class="form-check">
                <input
                  id="is_class_active"
                  v-model="schoolClass.is_active"
                  class="form-check-input d-block"
                  type="checkbox"
                >
              </div>
            </div>
            <div class="col checkbox-center">
              <label class="col-form-label" for="is_class_active"> Is Individual </label>
              <div class="form-check">
                <input
                  id="is_class_active"
                  v-model="schoolClass.is_individual"
                  class="form-check-input d-block"
                  type="checkbox"
                >
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <label for="class" class="col-form-label"> Google Meets URL </label>
              <input
                id="google_meets_url"
                v-model="schoolClass.google_meets_url"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': inputErrors['google_meets_url'] }"
              >
              <div class="invalid-feedback">
                {{ inputErrors["google_meets_url"] }}
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col">
              <div class="d-flex justify-content-between align-items-center mb-2">
                <h6 class="mb-0">
                  Class Students
                </h6>
                <button type="button" class="btn btn-outline-primary" @click="openSelectStudentsModal">
                  <i class="bi bi-plus-lg" /> Select Students
                </button>
              </div>
              <div v-if="schoolClass?.students?.length" class="attendance_table_wrapper">
                <SchoolClassStudentsTable
                  :student-ids="schoolClass.students"
                  @selected-students-changed="selectedStudentsChanged"
                />
              </div>
              <div v-else class="text-light-emphasis text-center py-2">
                Currently there are no students in this class.<br>
                Click the <strong>Select Students</strong> button to add them.
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import Timepicker from "@/components/ui/timepicker/Timepicker.vue";
import SelectInput from "@/components/ui/input/SelectInput.vue";
import SchoolClassStudentsTable from "@/components/modals/calendar/school_class/SchoolClassStudentsTable.vue";
import { IInputErrors } from "@/types/input";
import SchoolClass from "@/types/models/school_class";
import { useStudentStore } from "@/stores/student.store";
import { useModalStore } from "@/stores/modal.store";
import setsMixin from "@/mixins/sets.mixin";

export default defineComponent({
  name: "SchoolClassInfo",
  components: { SchoolClassStudentsTable, SelectInput, Timepicker },
  mixins: [mappingsMixin, setsMixin],
  props: {
    modelValue: {
      type: Object as PropType<SchoolClass>,
      default: () => {
        return {};
      },
    },
    inputErrors: {
      type: Object as PropType<IInputErrors>,
      default: () => {
        return {};
      },
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const studentStore = useStudentStore();
    const modalStore = useModalStore();
    return {
      studentStore,
      modalStore,
    };
  },
  computed: {
    schoolClass: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    selectedStudentsChanged(newSelectedStudents: number[]) {
      const oldStudents = new Set(this.schoolClass.students);
      const newStudents = new Set(newSelectedStudents);
      this.schoolClass.students = newSelectedStudents;
      this.studentStore.attendanceStudents = this.studentStore.attendanceStudents.filter((student) =>
        newSelectedStudents.includes(student.id as number),
      );

      console.log("selectedStudents changed: ", oldStudents, newSelectedStudents);
      if (this.setsAreEqual(oldStudents, newStudents) || this.isSetSubset(newStudents, oldStudents)) {
        // There is no need to refetch students if there was
        // no change or if some of the students were removed.
        return;
      }

      this.studentStore.resetState();
      this.studentStore.getAttendanceStudents(newSelectedStudents);
    },
    openSelectStudentsModal() {
      this.modalStore.openSelectStudentsSubModal(this.schoolClass.students, this.selectedStudentsChanged);
    },
  },
});
</script>

<style scoped lang="scss">
.school_class_tab {
  height: 100%;
  display: flex;
  flex-direction: column;

  .main-container {
    height: 100%;
    overflow: hidden auto;
    flex-grow: 1;
  }
  .row {
    row-gap: 0.6em;
  }
  label {
    font-weight: bold;
  }
}
</style>
